import { Route } from "badmagic/dist/types";

const basePath = `/support/v1`;

const userRoutes: Route[] = [
  {
    name: "Search Users",
    path: `${basePath}/users`,
    qsParams: [{ name: "page" }],
  },
  {
    name: "Fetch a User",
    path: `${basePath}/users/:user_id`,
  },
  {
    name: "Invite a user",
    path: `${basePath}/users`,
    method: "post",
    body: [
      { name: "company_id", required: true },
      { name: "first_name", required: true },
      { name: "last_name", required: true },
      { name: "email", required: true },
      { name: "role", required: true },
      { name: "phone" },
    ],
  },
];

export default userRoutes;
