import { Route } from "badmagic/dist/types";

const basePath = "/support/v1";

const taskFlagRoutes: Route[] = [
  {
    name: "Fetch Task Flags",
    path: `${basePath}/task-flags/:id`,
  },
  {
    name: "Create Task Flag",
    path: `${basePath}/task-flags`,
    method: "POST",
    body: [
      {
        name: "reason",
        required: true,
      },
      {
        name: "task_id",
        required: true,
      },
      {
        name: "job_id",
        required: true,
      },
      {
        name: "project_id",
        required: false,
      },
      {
        name: "created_by_email",
      },
    ],
  },
  {
    name: "Update Task Flag Reason",
    path: `${basePath}/task-flags/:id`,
    method: "PATCH",
    body: [
      {
        name: "Reason",
      },
      {
        name: "completed_by",
      },
      {
        name: "completed_by_email",
      },
    ],
  },
  {
    name: "Delete Task Flag",
    path: `${basePath}/task-flags/:id`,
    method: "DELETE",
  },
];

export default taskFlagRoutes;
