import { BadMagic } from "badmagic";

import { HistoryMetadata } from "../../plugins/HistoryMetadata";
import { AuthForm } from "../../plugins/AuthForm";
import { applyAxiosInterceptors } from "../../plugins/apply-axios-interceptors";

import workspaces from "../../workspaces";

export const BadMagicPage = () => (
  <BadMagic
    workspaces={workspaces}
    AuthForm={AuthForm}
    HistoryMetadata={HistoryMetadata}
    applyAxiosInterceptors={applyAxiosInterceptors}
  />
);
