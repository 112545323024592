import { Route } from "badmagic/dist/types";

import { jobTemplateTasks } from "../../example-values";

const basePath = "/api/v1";

const jobTemplateRoutes: Route[] = [
  {
    name: "Search Job Templates",
    path: `${basePath}/job-templates`,
    qsParams: [{ name: "name" }, { name: "company_id" }],
  },
  {
    name: "Fetch a Job Template",
    path: `${basePath}/job-templates/:job_template_id`,
  },
  {
    name: "Create a Job Template",
    path: `${basePath}/job-templates`,
    method: "POST",
    body: [
      { name: "name", required: true },
      { name: "company_id", required: true },
      {
        name: "tasks",
        type: "textarea",
        json: true,
        required: true,
        defaultValue: JSON.stringify(jobTemplateTasks, null, 2),
      },
    ],
  },
  {
    name: "Update a Job Template",
    path: `${basePath}/job-templates/:job_template_id`,
    method: "PATCH",
    body: [
      { name: "name" },
      {
        name: "tasks",
        type: "textarea",
        json: true,
        defaultValue: JSON.stringify(jobTemplateTasks, null, 2),
      },
    ],
  },
  {
    name: "Delete a Job Template",
    path: `${basePath}/job-templates/:job_template_id`,
    method: "DELETE",
  },
];

export default jobTemplateRoutes;
