import type { Route } from "badmagic/dist/types";

const basePath = "/api/v1/install-details";

const installDetailsRoutes: Route[] = [
  {
    name: "Fetch install detail options",
    path: `${basePath}/options`,
  },
];

export default installDetailsRoutes;
