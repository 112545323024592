import { Route } from "badmagic/dist/types";

const basePath = `/support/v1`;

const reportRoutes: Route[] = [
  {
    name: "Create / Read / Reset Installation Report",
    method: "POST",
    path: `${basePath}/reports/installations/:group_id`,
  },
];

export default reportRoutes;
