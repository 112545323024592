import { Link } from "react-router-dom";

import { BASE_PATH } from "./constants";

export function Home() {
  return (
    <main
      className="m-auto text-center max-w-xl w-full p-12 space-y-4"
      style={styles.home}
    >
      <h1 className="text-2xl font-bold">Alloy Install Dev Tools</h1>
      <ul className="text-left text-lg underline">
        <li>
          <Link to={`${BASE_PATH}/bad-magic`}>Bad Magic</Link>
        </li>
        <li>
          <a href={`${BASE_PATH}/oban`}>Oban Dashboard</a>
        </li>
        <li>
          <a href={`${BASE_PATH}/mailbox`}>Email Inbox</a>
        </li>
        <li>
          <a href={`${BASE_PATH}/emails`}>Email Templates</a>
        </li>
      </ul>
    </main>
  );
}

const styles = {
  home: {
    color: "#FBFCFD",
  },
};
