import { Route } from "badmagic/dist/types";

const basePath = "/api/v1";

const authenticationRoutes: Route[] = [
  {
    name: "Login",
    path: `${basePath}/login`,
    method: "POST",
    body: [
      {
        name: "email",
        required: true,
        placeholder: "your@domain.com",
      },
      {
        name: "password",
        required: true,
        type: "password",
      },
      {
        name: "user_company_id",
        required: false,
      },
    ],
  },
  {
    name: "Exchange Refresh Token",
    path: `${basePath}/refresh`,
    method: "POST",
    documentation: `
# Exchange Refresh Token

This will not work on badmagic because it needs a custom header.

To test this endpoint, send a request like:

\`\`\`
POST ${basePath}/refresh
{
    headers: {
        "Authorization-X-Refresh": "your_api_refresh_token (can be obtained from the login endpoint)",
    }
}
\`\`\`
    `,
  },
  {
    name: "Request Password Reset",
    path: `${basePath}/password-resets`,
    method: "POST",
    body: [{ name: "email" }],
    documentation: "Sends a password reset email",
  },
  {
    name: "Reset Password",
    path: `${basePath}/password-resets/:password_reset_token_id`,
    method: "POST",
    body: [{ name: "password" }],
    documentation:
      "`:password_reset_token_id` can be obtained from the password reset email or the database `password_resets.id`",
  },
  {
    name: "Validate Reset Password Token",
    path: `${basePath}/password-resets/:password_reset_token_id/validate`,
    documentation:
      "`:password_reset_token_id` can be obtained from the password reset email or the database `password_resets.id`",
  },
  {
    name: "Get Zendesk JWT Token for user",
    path: `${basePath}/zendesk-token`,
  },
  {
    name: "Generate 2FA Secret",
    path: `${basePath}/tfa-secret`,
  },
  {
    name: "Enable 2FA for user",
    path: `${basePath}/tfa-enable`,
    method: "POST",
    body: [
      {
        name: "secret",
        required: true,
      },
      {
        name: "otp",
        label: "One-Time Password",
        required: true,
      },
    ],
  },
  {
    name: "Disable 2FA for user",
    path: `${basePath}/tfa-disable`,
    method: "DELETE",
    body: [{ name: "otp", label: "One-Time Password", required: true }],
  },
];

export default authenticationRoutes;
