import { Route, Workspace } from "badmagic/dist/types";

const publicRoutes: Route[] = [
  {
    name: "Health Check",
    path: "/health",
  },
  {
    name: "Get Current Version",
    path: "/version",
  },
];

const publicWorkspace: Workspace = {
  id: "public",
  name: "Public API",
  config: {
    baseUrl: window.location.origin,
    loginPath: "/api/v1/login",
    refreshPath: "/api/v1/refresh",
    pathToTokens: "data",
    authStrategy: "bearer",
  },
  routes: publicRoutes,
};

export default publicWorkspace;
