import { Route, Switch } from "react-router-dom";

import { BadMagicPage } from "./routes/index/BadMagic";
import { BASE_PATH } from "./constants";
import { Home } from "./home";

export function BadMagicRouter() {
  return (
    <Switch>
      <Route exact path={`${BASE_PATH}/bad-magic`}>
        <BadMagicPage />
      </Route>
      <Route path={BASE_PATH}>
        <Home />
      </Route>
    </Switch>
  );
}
