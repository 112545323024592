import { useState, useMemo, useEffect, useCallback } from "react";
import { StyleSheet } from "react-native";

import { Select, Label, Success, Error, useGlobalContext } from "badmagic";
import { AuthForm as AuthFormType } from "badmagic/dist/types";

import { Profile } from "../types";

import { CreateBearerProfile } from "./CreateBearerProfile";
import { CreateHmacProfile } from "./CreateHmacProfile";
import { RefreshTokenButton } from "./RefreshTokenButton";

export const AuthForm: AuthFormType = ({ workspaceConfig }) => {
  const { darkMode } = useGlobalContext();
  const [success, setSuccess] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);

  const [profiles, setProfilesInState] = useState<Profile[]>([]);
  const [activeProfile, setActiveProfileInState] = useState<Profile | null>(
    null
  );

  // Fetches Profiles from local storage on load, also fetches Active Profile
  useEffect(() => {
    try {
      const authProfiles = localStorage.getItem("auth-profiles");
      const parsedProfiles = authProfiles ? JSON.parse(authProfiles) : [];
      setProfilesInState(parsedProfiles);

      const activeProfile = localStorage.getItem("active-profile");

      if (activeProfile) {
        setActiveProfileInState(JSON.parse(activeProfile));
      }
    } catch (err) {
      console.error(err);
    }
  }, []);

  const filteredProfiles = useMemo(() => {
    switch (workspaceConfig?.authStrategy) {
      case "bearer":
        return profiles.filter((profile) => !!profile.accessToken);
      case "hmac":
        return profiles.filter((profile) => !!profile.key);
      default:
        return profiles;
    }
  }, [workspaceConfig, profiles]);

  const setProfiles = useCallback(
    (profiles: Profile[]) => {
      setProfilesInState(profiles);
      localStorage.setItem("auth-profiles", JSON.stringify(profiles));
    },
    [setProfilesInState]
  );

  const setActiveProfile = useCallback(
    (activeProfile: Profile | null) => {
      setActiveProfileInState(activeProfile);
      localStorage.setItem("active-profile", JSON.stringify(activeProfile));
    },
    [setActiveProfileInState]
  );

  const styles = useMemo(() => {
    return {
      authFormBackground: darkMode
        ? "bg-gray-900 border-gray-700"
        : "bg-gray-200 border-gray-400",
    };
  }, [darkMode]);

  return (
    <div
      className={`border ${styles.authFormBackground} rounded pt-4 px-4 mb-4`}
    >
      {profiles?.length ? (
        <>
          <Label>Select an Auth Profile to use with this API Request</Label>

          <Select
            value={activeProfile?.name}
            style={styleSheet.marginRight}
            onChange={(e) => {
              if (e.currentTarget.value) {
                const profile = profiles.find(
                  (profile) => profile.name === e.currentTarget.value
                );
                if (profile) {
                  localStorage.setItem(
                    "active-profile",
                    JSON.stringify(profile)
                  );
                  setActiveProfile(profile);
                }
              } else {
                localStorage.removeItem("active-profile");
                setActiveProfile(null);
              }
            }}
          >
            <option value="">Select Profile</option>
            {filteredProfiles.map((profile) => (
              <option key={profile.name} value={profile.name}>
                {profile.name}
              </option>
            ))}
          </Select>
        </>
      ) : null}
      <div className="flex flex-wrap">
        {workspaceConfig.authStrategy === "bearer" ? (
          <CreateBearerProfile
            workspaceConfig={workspaceConfig}
            setError={setError}
            setSuccess={setSuccess}
            setProfiles={setProfiles}
            profiles={profiles}
            setActiveProfile={setActiveProfile}
          />
        ) : null}
        {workspaceConfig.authStrategy === "hmac" ? (
          <CreateHmacProfile
            setError={setError}
            setSuccess={setSuccess}
            setProfiles={setProfiles}
            profiles={profiles}
            setActiveProfile={setActiveProfile}
          />
        ) : null}
        <RefreshTokenButton
          activeProfile={activeProfile}
          workspaceConfig={workspaceConfig}
          setError={setError}
          setSuccess={setSuccess}
          setProfiles={setProfiles}
          profiles={profiles}
          setActiveProfile={setActiveProfile}
        />
      </div>
      {success || error ? (
        <div className="m-2">
          <AnySuccess>{success}</AnySuccess>
          <Error>{error}</Error>
        </div>
      ) : null}
    </div>
  );
};

const AnySuccess = Success as any; // todo: update badmagic with proper type

const styleSheet = StyleSheet.create({
  marginRight: { marginRight: "4px" },
});
