export const jobTemplateTasks = [
  {
    name: "Pay Paul $100",
    type: "checkbox",
    description: "😁",
  },
  {
    name: "Take a picture of Fido",
    type: "image-upload",
    description: "Protip: He likes doggie treats",
  },
  {
    name: "Install a lock",
    description: "YRD256",
    type: "device-install",
    meta: { device_category: "entry_control" },
  },
];
