import { Route } from "badmagic/dist/types";

const basePath = "/api/v1";

const routes: Route[] = [
  {
    name: "Create a Team",
    path: `${basePath}/teams`,
    method: "post",
    body: [
      {
        name: "name",
      },
    ],
  },
  {
    name: "Search for Teams",
    path: `${basePath}/teams`,
    method: "get",
    qsParams: [
      {
        name: "name",
      },
    ],
  },
  {
    name: "Update a Team",
    path: `${basePath}/teams/:team_id`,
    method: "put",
    body: [
      {
        name: "name",
      },
    ],
  },
  {
    name: "Delete a Team",
    path: `${basePath}/teams/:team_id`,
    method: "delete",
  },
  {
    name: "Assign Team Member",
    path: `${basePath}/user-teams`,
    method: "post",
    body: [
      {
        name: "team_id",
      },
      {
        name: "user_id",
      },
    ],
  },
  {
    name: "Remove Team Member",
    path: `${basePath}/user-teams/:user_team_id`,
    method: "delete",
  },
];

export default routes;
