import { Workspace } from "badmagic/dist/types";

const basePath = `/api/v1`;

const organizationRemoteIdDocumentation = `
## Organization remote ids

\`null\` for all access

\`[]\` for no access

\`["1", "2", "3", "4"]\` to only access those orgs
`;
const adminWorkspace: Workspace = {
  id: "admin",
  name: "Administrative API",
  config: {
    baseUrl: window.location.origin,
    authStrategy: "hmac",
  },
  routes: [
    {
      name: "Search Companies",
      path: `${basePath}/companies`,
    },
    {
      name: "Fetch Company",
      path: `${basePath}/companies/:company_id`,
    },
    {
      name: "Create Company",
      path: `${basePath}/companies`,
      method: "POST",
      body: [
        { name: "name" },
        {
          name: "organization_remote_ids",
          json: true,
          defaultValue: JSON.stringify("[]"),
        },
      ],
      documentation: organizationRemoteIdDocumentation,
    },
    {
      name: "Update a Company",
      path: `${basePath}/companies/:company_id`,
      method: "PATCH",
      body: [
        { name: "name" },
        {
          name: "organization_remote_ids",
          json: true,
          defaultValue: JSON.stringify("[]"),
        },
      ],
      documentation: organizationRemoteIdDocumentation,
    },
    {
      name: "Delete a Company",
      path: `${basePath}/companies/:company_id`,
      method: "DELETE",
    },
  ],
};

export default adminWorkspace;
