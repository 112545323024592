import { Route } from "badmagic/dist/types";

const basePath = "/api/v1";

const userRoutes: Route[] = [
  {
    name: "Create (Invite) a User",
    path: `${basePath}/users`,
    method: "POST",
    body: [
      { name: "first_name", required: true },
      { name: "last_name", required: true },
      { name: "email", required: true },
      { name: "phone" },
      {
        name: "role",
        options: [
          { label: "Admin", value: "admin" },
          { label: "Manager", value: "manager" },
          { label: "Integrator", value: "integrator" },
          { label: "Technician", value: "technician" },
        ],
        required: true,
      },
    ],
  },
  {
    name: "Validate Invitation",
    path: `${basePath}/user-invitations/:user_invitation_id/validate`,
  },
  {
    name: "Accept Invitation",
    path: `${basePath}/user-invitations/:user_invitation_id/accept`,
    method: "POST",
    body: [{ name: "password", required: true }],
  },
  {
    name: "Resend Invitation (Legacy)",
    path: `${basePath}/user-invitations/:user_invitation_id/resend`,
    method: "POST",
  },
  {
    name: "Resend Invitation",
    path: `${basePath}/user-invitations/resend`,
    method: "POST",
    body: [
      {
        name: "user_id",
      },
    ],
  },
  {
    name: "Fetch Current User",
    path: `${basePath}/user`,
  },
  {
    name: "Update Current User",
    path: `${basePath}/user`,
    method: "PATCH",
    body: [
      { name: "first_name" },
      { name: "last_name" },
      { name: "email" },
      { name: "phone" },
      { name: "current_password", required: true },
    ],
  },
  {
    name: "Update Current User's Password",
    path: `${basePath}/user/password`,
    method: "PATCH",
    body: [{ name: "current_password" }, { name: "new_password" }],
  },
  {
    name: "Update User",
    path: `${basePath}/users/:user_id`,
    method: "PATCH",
    body: [
      { name: "first_name" },
      { name: "last_name" },
      { name: "email" },
      { name: "phone" },
      {
        name: "is_active",
        options: [
          { label: "True", value: "true" },
          { label: "False", value: "false" },
        ],
        required: false,
      },
      {
        name: "role",
        options: [
          { label: "Admin", value: "admin" },
          { label: "Manager", value: "manager" },
          { label: "Integrator", value: "integrator" },
          { label: "Technician", value: "technician" },
        ],
        required: false,
      },
    ],
  },
  {
    name: "Get list of users",
    path: `${basePath}/users`,
    qsParams: [
      {
        name: "company_id",
      },
      {
        name: "name",
        label: "Name (Partial Matching)",
      },
      {
        name: "company_name",
      },
    ],
  },
  {
    name: "Get user",
    path: `${basePath}/users/:user_id`,
  },
  {
    name: "Generate Current User Key & Secret",
    path: `${basePath}/user/authentication`,
    method: "POST",
  },
  {
    name: "Fetch Current User's Key",
    path: `${basePath}/user/key`,
  },
];

export default userRoutes;
