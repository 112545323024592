import { Route } from "badmagic/dist/types";

const basePath = "/api/v1";

const projectRoutes: Route[] = [
  {
    name: "Search Projects",
    path: `${basePath}/projects`,
    qsParams: [
      { name: "name" },
      { name: "company_id" },
      { name: "property_id" },
      { name: "property_remote_ids", json: true, defaultValue: "[]" },
      {
        name: "completed",
        label: "Completed",
        options: [
          { label: "True", value: "true" },
          { label: "False", value: "false" },
        ],
      },
      { name: "contains_date", type: "date", required: false },
      { name: "start_date", type: "date" },
      { name: "end_date", type: "date" },
      { name: "starts_in_days", type: "number" },
      {
        name: "deleted",
        label: "Deleted",
        options: [
          { label: "True", value: "true" },
          { label: "False", value: "false" },
        ],
      },
      { name: "assigned_to_user_ids", json: true, defaultValue: "[]" },
    ],
  },
  {
    name: "Fetch a Project",
    path: `${basePath}/projects/:project_id`,
  },
  {
    name: "Create a Project",
    path: `${basePath}/projects`,
    method: "POST",
    body: [
      { name: "property_remote_id", required: true },
      { name: "name", required: true },
      { name: "start_date", type: "date", required: true },
      { name: "end_date", type: "date", required: true },
      {
        name: "install_type",
      },
      {
        name: "device_types",
        json: true,
        defaultValue: "[]",
      },
      {
        name: "reasons",
        json: true,
        defaultValue: "[]",
      },
    ],
    documentation: `
# Create a project

\`property_remote_id\` is the \`remote_id\` on our property table.

Current install_type, device_types, and reasons options can be found from "/api/v1/install-details/options"
`,
  },
  {
    name: "Update a Project",
    path: `${basePath}/projects/:project_id`,
    method: "PATCH",
    body: [
      { name: "name" },
      { name: "start_date", type: "date" },
      { name: "end_date", type: "date" },
    ],
  },
  {
    name: "Delete a Project",
    path: `${basePath}/projects/:project_id`,
    method: "DELETE",
  },
  {
    name: "Mark a Project as Completed",
    path: `${basePath}/projects/:project_id/complete`,
    method: "POST",
  },
  {
    name: "Mark a Project as Not Completed",
    path: `${basePath}/projects/:project_id/uncomplete`,
    method: "POST",
  },
  {
    name: "Declare that a Project Cannot Be Completed",
    path: `${basePath}/projects/:project_id/cannot-complete`,
    method: "POST",
    body: [{ name: "reason", type: "string" }],
  },
  {
    name: "Set Users Assigned to a Project",
    description:
      "Overwrites the old list; any previously-assigned users not in the list will be unassigned",
    path: `${basePath}/projects/:project_id/users`,
    method: "PUT",
    body: [
      {
        name: "user_ids",
        type: "textarea",
        json: true,
        required: true,
        defaultValue: `["dc37d8dc-134a-450d-b497-a5630289e50f"]`,
      },
    ],
    documentation: `user_ids is an array of user UUIDs`,
  },
  {
    name: "Unassign a User from a Project",
    path: `${basePath}/projects/:project_id/users/:user_id`,
    method: "DELETE",
  },
  {
    name: "Restores a Deleted Project",
    path: `${basePath}/projects/:project_id/restore`,
    method: "PUT",
  },
  {
    name: "Get all units in a project with a low battery alert",
    path: `${basePath}/projects/:project_id/battery-alerts`,
    qsParams: [
      { name: "limit", type: "number" },
      { name: "page", type: "number" },
    ],
  },
];

export default projectRoutes;
