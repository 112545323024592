import { Workspace } from "badmagic/dist/types";

import projects from "./support-app/projects";
import jobs from "./support-app/jobs";
import users from "./support-app/users";
import comments from "./support-app/comments";
import reports from "./support-app/reports";
import taskFlags from "./support-app/task-flags";
import companies from "./support-app/companies";

const supportAppWorkspace: Workspace = {
  id: "support",
  name: "Support App API",
  config: {
    baseUrl: window.location.origin,
    authStrategy: "hmac",
  },
  routes: [
    ...projects,
    ...jobs,
    ...users,
    ...comments,
    ...reports,
    ...taskFlags,
    ...companies,
  ],
};

export default supportAppWorkspace;
