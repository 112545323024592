const basePath = `/super-admin/v1`;

const companyPropertySyncSettingRoutes = [
  {
    name: "Fetch Company Property Sync Setting",
    path: `${basePath}/company-property-sync-settings/:id`,
  },
  {
    name: "Create Company Property Sync Setting",
    path: `${basePath}/company-property-sync-settings`,
    method: "POST",
    body: [
      {
        name: "name",
        type: "string",
        required: true,
      },
      {
        name: "start_date",
        type: "date",
      },
      {
        name: "end_date",
        type: "date",
      },
      {
        name: "duration",
        type: "number",
      },
      {
        name: "create_projects_as_id",
        type: "string",
        required: true,
      },
      {
        name: "company_id",
        type: "string",
        required: true,
      },
      {
        name: "is_enabled",
        type: "boolean",
        required: true,
      },
    ],
  },
  {
    name: "Update Company Property Sync Setting",
    path: `${basePath}/company-property-sync-settings/:id`,
    method: "PUT",
    body: [
      {
        name: "name",
        type: "string",
      },
      {
        name: "start_date",
        type: "date",
      },
      {
        name: "end_date",
        type: "date",
      },
      {
        name: "duration",
        type: "number",
      },
      {
        name: "create_projects_as_id",
        type: "string",
      },
      {
        name: "company_id",
        type: "string",
      },
      {
        name: "is_enabled",
        type: "boolean",
      },
    ],
  },
  {
    name: "Delete Company Property Sync Setting",
    path: `${basePath}/company-property-sync-settings/:id`,
    method: "DELETE",
  },
];

export default companyPropertySyncSettingRoutes;
