import { Route } from "badmagic/dist/types";

const basePath = "/api/v1";

const miscellaneousRoutes: Route[] = [
  {
    name: "Fetch Minimum Required And Current Mobile App Versions",
    path: `${basePath}/app-versions`,
  },
  {
    name: "Fetch Paginated Release Notes",
    path: `${basePath}/release-notes`,
    qsParams: [
      {
        name: "page",
        label: "page",
      },
    ],
  },
  {
    name: "Provide app feedback",
    path: `${basePath}/feedback`,
    method: "POST",
    body: [{ name: "feedback", required: true }],
  },
  {
    name: "Report Issue to Slack Channel",
    path: `${basePath}/report-issue`,
    method: "POST",
    body: [
      { name: "type", required: true },
      { name: "description", required: true },
      {
        name: "fields",
        required: true,
        type: "textarea",
        json: true,
        defaultValue: '[{"title": "User", "value": "test@smartrent.com" }]',
      },
    ],
  },
  {
    name: "Get recent events for channel by topic",
    path: `${basePath}/channel/:topic`,
  },
  {
    name: "Get a workflow",
    path: `${basePath}/workflows/:id`,
  },
];

export default miscellaneousRoutes;
