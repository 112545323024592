import { Route } from "badmagic/dist/types";

const basePath = "/api/v1";

const propertyRoutes: Route[] = [
  {
    name: "Search Properties",
    path: `${basePath}/properties`,
  },
  {
    name: "Show Property",
    path: `${basePath}/properties/:property_id`,
  },
  {
    name: "Unit Search Options",
    path: `${basePath}/properties/:property_id/units/search-options`,
    qsParams: [
      { name: "building" },
      {
        name: "buildings",
        type: "textarea",
        json: true,
        defaultValue: "[]",
      },
      { name: "floor" },
      {
        name: "floors",
        type: "textarea",
        json: true,
        defaultValue: "[]",
      },
    ],
  },
];

export default propertyRoutes;
