import { Workspace } from "badmagic/dist/types";

import mobileAppWorkspace from "./workspaces/mobile-app";
import supportAppWorkspace from "./workspaces/support-app";
import adminWorkspace from "./workspaces/admin";
import superAdminWorkSpace from "./workspaces/super-admin";
import publicWorkspace from "./workspaces/public";

const workspaces: Workspace[] = [
  publicWorkspace,
  mobileAppWorkspace,
  supportAppWorkspace,
  adminWorkspace,
  superAdminWorkSpace,
];

export default workspaces;
