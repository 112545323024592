import { Route } from "badmagic/dist/types";

const basePath = `/support/v1`;

const projectRoutes: Route[] = [
  {
    name: "Search Projects",
    path: `${basePath}/projects`,
    qsParams: [
      { name: "name" },
      { name: "company_id" },
      { name: "property_id" },
      {
        name: "completed",
        label: "Completed",
        options: [
          { label: "True", value: "true" },
          { label: "False", value: "false" },
        ],
      },
      { name: "contains_date", type: "date", required: false },
      {
        name: "install_type",
        type: "string",

        options: [
          {
            value: "new_construction",
            label: "New Construction",
          },
          { value: "retrofits", label: "Retrofits" },
          { value: "hub_conversions", label: "Hub Conversions" },
          { value: "quality_assurance", label: "Quality Assurance" },
          { value: "opt-out_installs", label: "Opt-Out Installs" },
          { value: "field_return", label: "Field Return" },
        ],
      },
      {
        name: "device_type",
        type: "string",
        options: [
          {
            value: "entry_control",
            label: "Door Lock",
          },
          {
            value: "switch_binary",
            label: "Outlet/Plug/Switch",
          },
          { value: "switch_multilevel", label: "Dimmer Switch/Bulb" },
          { value: "shade_control", label: "Shade Control" },
          { value: "repeater_slave", label: "Repeater" },
          { value: "thermostat", label: "Thermostat" },
          {
            value: "garage_door_controller",
            label: "Garage Door Controller",
          },
          {
            value: "siren",
            label: "Siren",
          },
        ],
      },
    ],
  },
];

export default projectRoutes;
