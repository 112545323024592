import { Route } from "badmagic/dist/types";

const basePath = `/support/v1`;

const jobRoutes: Route[] = [
  {
    name: "Search Jobs",
    path: `${basePath}/jobs`,
    qsParams: [
      {
        name: "status",
        options: [
          { label: "Opt out", value: "opt-out" },
          { label: "Cannot complete", value: "cannot-complete" },
          { label: "Completed", value: "completed" },
          { label: "Qa ready", value: "qa-ready" },
          { label: "Rescheduled", value: "rescheduled" },
          { label: "Pending", value: "pending" },
          { label: "Overdue", value: "overdue" },
          { label: "Current", value: "current" },
        ],
      },
      {
        name: "completed",
        label: "Completed",
        options: [
          { label: "True", value: "true" },
          { label: "False", value: "false" },
        ],
      },
      {
        name: "has_device_install_tasks",
        label: "Has Device Install Task(s)",
        options: [
          { label: "True", value: "true" },
          { label: "False", value: "false" },
        ],
      },
      { name: "name" },
      { name: "company_id" },
      { name: "project_id" },
      { name: "property_id" },
      { name: "unit_id" },
      { name: "unit_remote_id" },
      { name: "contains_date", type: "date", required: false },
      {
        name: "install_type",
        type: "string",

        options: [
          {
            value: "new_construction",
            label: "New Construction",
          },
          { value: "retrofits", label: "Retrofits" },
          { value: "hub_conversions", label: "Hub Conversions" },
          { value: "quality_assurance", label: "Quality Assurance" },
          { value: "opt-out_installs", label: "Opt-Out Installs" },
          { value: "field_return", label: "Field Return" },
        ],
      },
      {
        name: "device_type",
        type: "string",
        options: [
          {
            value: "hub",
            label: "Hub",
          },
          {
            value: "ring_device",
            label: "Ring Device",
          },
          {
            value: "entry_control",
            label: "Door Lock",
          },
          {
            value: "switch_binary",
            label: "Outlet/Plug/Switch",
          },
          { value: "switch_multilevel", label: "Dimmer Switch/Bulb" },
          { value: "shade_control", label: "Shade Control" },
          { value: "repeater_slave", label: "Repeater" },
          { value: "thermostat", label: "Thermostat" },
          {
            value: "garage_door_controller",
            label: "Garage Door Controller",
          },
          {
            value: "siren",
            label: "Siren",
          },
        ],
      },
      {
        name: "flagged",
        type: "boolean",
      },
    ],
  },
  {
    name: "Fetch Job Images",
    path: `${basePath}/jobs/:job_id/tasks-images`,
    qsParams: [
      {
        name: "page",
      },
    ],
  },
];

export default jobRoutes;
