import { Route } from "badmagic/dist/types";

const basePath = "/api/v1";

const deviceRoutes: Route[] = [
  {
    name: "Fetch Unit Devices",
    path: `${basePath}/units/:unit_id/devices`,
  },
  {
    name: "Fetch Device",
    path: `${basePath}/devices/:device_id`,
  },
  {
    name: "Delete Device",
    path: `${basePath}/units/:unit_id/devices/:device_id`,
    method: "DELETE",
  },
  {
    name: "Rename Device",
    path: `${basePath}/units/:unit_id/devices/:device_id/rename`,
    method: "PATCH",
    body: [
      {
        name: "name",
        label: "New Name",
      },
    ],
  },
  {
    name: "Make Primary Lock",
    path: `${basePath}/devices/:device_id/make-primary-lock`,
    method: "PATCH",
  },
  {
    name: "Get device categories",
    path: `${basePath}/device-categories`,
  },
  {
    name: "Poll Device Battery",
    path: `${basePath}/devices/:device_id/poll-battery`,
    method: "PATCH",
  },
  {
    name: "Get Device",
    path: `${basePath}/devices/:device_id`,
  },
  {
    name: "Search device name suggestions",
    path: `${basePath}/devices/name-suggestions`,
    qsParams: [
      {
        name: "category",
        options: [
          { value: "thermostat", label: "thermostat" },
          { value: "switch_multilevel", label: "dimmer" },
          { value: "shade_control", label: "shade" },
          { value: "entry_control", label: "lock" },
          { value: "repeater_slave", label: "repeater" },
          {
            value: "garage_door_controller",
            label: "garage door controller",
          },
          { value: "siren", label: "siren" },
          { value: "valve", label: "valve" },
          { value: "gateway", label: "gateway" },
          { value: "leak_sensor", label: "leak sensor" },
          { value: "motion_sensor", label: "motion sensor" },
          { value: "contact_sensor", label: "contact sensor" },
        ],
      },
    ],
  },
  {
    name: "Get device name suggestions",
    path: `${basePath}/devices/:device_id/suggested-names`,
  },
  {
    name: "Dismiss a device battery alert on a job",
    path: `${basePath}/jobs/:job_id/devices/:device_id/battery-alert`,
    method: "POST",
    body: [
      {
        name: "reason",
        label: "Reason for dismissal",
        required: true,
      },
    ],
  },
];

export default deviceRoutes;
