import { Route } from "badmagic/dist/types";

const basePath = "/api/v1";

const taskNameSuggestionRoutes: Route[] = [
  {
    name: "Fetch list of task name suggestions",
    path: `${basePath}/task-name-suggestions`,
    qsParams: [
      {
        name: "task_type_uuid",
        label: "Task Type UUID",
      },
    ],
  },
  {
    name: "Fetch task name suggestion",
    path: `${basePath}/task-name-suggestions/:uuid`,
  },
];

export default taskNameSuggestionRoutes;
