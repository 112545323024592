import { Route } from "badmagic/dist/types";

const featureFlagRoutes: Route[] = [
  {
    name: "Check if a flag is enabled",
    path: "/api/v1/feature-flags/:id",
    qsParams: [{ name: "scope", options: [{ value: "company" }] }],
  },
];

export default featureFlagRoutes;
