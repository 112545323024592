import { useState, useEffect } from "react";
import { Label, TextInput, Button } from "badmagic";

export function CreateHmacProfile({
  setProfiles,
  profiles,
  setError,
  setSuccess,
  setActiveProfile,
}) {
  const [name, setName] = useState("");
  const [key, setKey] = useState("");
  const [secret, setSecret] = useState("");
  const [collapsed, setCollapsed] = useState(true);

  useEffect(() => {
    if (!profiles?.length) {
      setCollapsed(false);
    }
  }, [profiles]);

  return (
    <div className="my-4">
      {collapsed ? (
        <Button
          onClick={() => setCollapsed(!collapsed)}
          title="Create Auth Profile"
        >
          <div className="flex items-center justify-center">
            + <div className="ml-2">Create Profile</div>
          </div>
        </Button>
      ) : null}
      {!collapsed ? (
        <div className="my-4">
          <Label>Create An Auth Profile</Label>
          <TextInput
            type="text"
            value={name}
            placeholder="Profile Name"
            className="mb-1"
            onChange={(e) => setName(e.currentTarget.value)}
          />
          <TextInput
            type="text"
            value={key}
            placeholder="Key"
            className="mb-1"
            onChange={(e) => setKey(e.currentTarget.value)}
          />
          <TextInput
            type="password"
            value={secret}
            placeholder="Secret"
            className="mb-1"
            onChange={(e) => setSecret(e.currentTarget.value)}
          />
          <div className="flex mt-4">
            <button
              className="bg-red-500 hover:bg-red-700 text-white font-semibold py-2 px-4 rounded"
              onClick={() => {
                setError(null);
                setSuccess(null);
                setCollapsed(!collapsed);
              }}
            >
              Cancel
            </button>
            <Button
              className="ml-2"
              onClick={() => {
                setError(null);
                setSuccess(null);

                if (!key) {
                  setError("Please specify a key");
                  return;
                } else if (!secret) {
                  setError("Please specify a secret");
                  return;
                } else if (!name) {
                  setError("Please give your profile a name");
                  return;
                }

                setSuccess("Profile created");

                const newProfiles = [...profiles];
                const newProfile = {
                  name,
                  key,
                  secret,
                };

                // Checks if the profile already existed in `auth-profiles`
                // If the profile already exists, it replaces the pre-existing profile with the new profile
                // If it didn't exist, it appends to the array
                const profileIndex = newProfiles.findIndex((profile) => {
                  return profile.name === newProfile.name;
                });
                if (profileIndex !== -1) {
                  newProfiles.splice(profileIndex, 1, newProfile); // mutates array; replaces old profile with new
                } else {
                  newProfiles.push(newProfile); // appends
                }

                setProfiles(newProfiles);
                setActiveProfile(newProfile);
                setCollapsed(true);
              }}
            >
              Save Profile
            </Button>
          </div>
        </div>
      ) : null}
    </div>
  );
}
