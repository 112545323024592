import { Route } from "badmagic/dist/types";

const basePath = `/support/v1`;

const commentRoutes: Route[] = [
  {
    name: "Fetch Comments for Job",
    path: `${basePath}/jobs/:job_id/comments`,
    qsParams: [
      {
        name: "page",
      },
    ],
  },
];

export default commentRoutes;
