import { Route } from "badmagic/dist/types";

const basePath = "/api/v1";

const unitRoutes: Route[] = [
  {
    name: "Units",
    path: `${basePath}/units`,
  },
  {
    name: "Show Unit",
    path: `${basePath}/units/:unit_id`,
  },
  {
    name: "Get Unit Satus",
    path: `${basePath}/units/:unit_id/status`,
  },
  {
    name: "Import units for company",
    path: `${basePath}/units/import`,
    deprecated: true,
  },
  {
    name: "Get the installer code for a unit's primary lock",
    path: `${basePath}/units/:unit_id/installer-code`,
  },
  {
    name: "Provision smart start device",
    path: `${basePath}/units/:unit_id/smartstart`,
    body: [{ name: "code" }],
    method: "POST",
  },
  {
    name: "Property Manager Code",
    path: `${basePath}/units/:unit_id/property-manager-code`,
    method: "GET",
  },
];

export default unitRoutes;
