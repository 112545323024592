import { Route } from "badmagic/dist/types";

const basePath = `/api/v1`;

const userCompanyRoutes: Route[] = [
  {
    name: "List User Companies",
    path: `${basePath}/user-companies`,
  },
  {
    name: "Change Company",
    path: `${basePath}/users/change-company`,
    method: "put",
    body: [
      {
        name: "user_company_id",
      },
    ],
  },
];

export default userCompanyRoutes;
