import { useState, useEffect } from "react";
import { get } from "lodash-es";
import axios from "axios";
import { Label, TextInput, Button } from "badmagic";

export function CreateBearerProfile({
  workspaceConfig,
  setProfiles,
  profiles,
  setError,
  setSuccess,
  setActiveProfile,
}) {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [collapsed, setCollapsed] = useState(true);

  // Auto-collapse if we have profiles or if a new profile is added
  useEffect(() => {
    setCollapsed(!!profiles?.length);
  }, [profiles]);

  return (
    <div className="my-4">
      {collapsed ? (
        <Button
          onClick={() => setCollapsed(!collapsed)}
          title="Create Auth Profile"
        >
          <div className="flex items-center justify-center">
            + <div className="ml-2">Create Profile</div>
          </div>
        </Button>
      ) : null}
      {!collapsed ? (
        <div className="my-4">
          <Label>Create An Auth Profile</Label>
          <TextInput
            type="text"
            value={name}
            placeholder="Profile Name"
            className="mb-1"
            onChange={(e) => setName(e.currentTarget.value)}
          />
          <TextInput
            type="text"
            value={email}
            placeholder="Email Address"
            className="mb-1"
            onChange={(e) => setEmail(e.currentTarget.value)}
          />
          <TextInput
            type="password"
            value={password}
            placeholder="Password"
            className="mb-1"
            onChange={(e) => setPassword(e.currentTarget.value)}
          />
          <div className="flex mt-4">
            <button
              className="bg-red-500 hover:bg-red-700 text-white font-semibold py-2 px-4 rounded"
              onClick={() => {
                setError(null);
                setSuccess(null);
                setCollapsed(!collapsed);
              }}
            >
              Cancel
            </button>
            <Button
              className="ml-2"
              onClick={async () => {
                setError(null);
                setSuccess(null);

                if (!email) {
                  setError("Please specify an email address");
                  return;
                } else if (!password) {
                  setError("Please specify a password");
                  return;
                } else if (!name) {
                  setError("Please give your profile a name");
                  return;
                }

                setSuccess("Logging in, one moment");

                await axios({
                  url: `${workspaceConfig.baseUrl}${workspaceConfig.loginPath}`,
                  method: "POST",
                  data: {
                    email,
                    password,
                  },
                })
                  .then((response) => {
                    const accessToken = get(
                      response,
                      `${workspaceConfig.pathToTokens}.access_token`
                    );
                    const refreshToken = get(
                      response,
                      `${workspaceConfig.pathToTokens}.refresh_token`
                    );

                    if (accessToken && refreshToken) {
                      setSuccess("Profile created and logged in");

                      const newProfiles = [...profiles];
                      const newProfile = {
                        name,
                        accessToken,
                        refreshToken,
                        email,
                        ...(process.env.NODE_ENV === "development" && {
                          password,
                        }),
                      };

                      // Checks if the profile already existed in `auth-profiles`
                      // If the profile already exists, it replaces the pre-existing profile with the new profile
                      // If it didn't exist, it appends to the array
                      const profileIndex = newProfiles.findIndex((profile) => {
                        return profile.name === newProfile.name;
                      });
                      if (profileIndex !== -1) {
                        newProfiles.splice(profileIndex, 1, newProfile); // mutates array; replaces old profile with new
                      } else {
                        newProfiles.push(newProfile); // appends
                      }

                      setProfiles(newProfiles);
                      setActiveProfile(newProfile);
                      setCollapsed(true);
                    } else {
                      setError(
                        "Unable to fetch access_token and refresh_token from that endpoint, please contact Engineering."
                      );
                    }
                  })
                  .catch((err) => {
                    console.error(err);
                    if (err?.response?.data) {
                      setError(
                        `[${err?.response?.status}] - ${JSON.stringify(
                          err.response.data
                        )}`
                      );
                    } else {
                      setError(err.message);
                    }
                  });
              }}
            >
              Save Profile
            </Button>
          </div>
        </div>
      ) : null}
    </div>
  );
}
