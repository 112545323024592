import { Route } from "badmagic/dist/types";

const bodyAttributes = [
  {
    name: "name",
    type: "string",
  },
  {
    name: "start_date",
    type: "date",
  },
  {
    name: "end_date",
    type: "date",
  },
  {
    name: "job_template_id",
    type: "string",
  },
  {
    name: "is_enabled",
    type: "boolean",
  },
  {
    name: "company_id",
    type: "string",
  },
  {
    name: "create_jobs_as_id",
    type: "string",
  },
];

const basePath = `/super-admin/v1`;

const companyUnitSyncSettingRoutes: Route[] = [
  {
    name: "Fetch Company Unit Sync Setting",
    path: `${basePath}/company-unit-sync-settings/:id`,
  },
  {
    name: "Create Company Unit Sync Setting",
    path: `${basePath}/company-unit-sync-settings`,
    method: "POST",
    body: bodyAttributes,
  },
  {
    name: "Update Company Unit Sync Setting",
    path: `${basePath}/company-unit-sync-settings/:id`,
    method: "PUT",
    body: bodyAttributes,
  },
  {
    name: "Delete Company Unit Sync Setting",
    path: `${basePath}/company-unit-sync-settings/:id`,
    method: "DELETE",
  },
];

export default companyUnitSyncSettingRoutes;
