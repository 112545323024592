import type {
  AxiosRequestHeaders,
  AxiosResponse,
  AxiosError,
  Axios,
} from "axios";
import CryptoJS from "crypto-js";

type AuthProfile = Record<string, any> | null;

/**
 * If `active-profile` is set in local storage, this function returns that active-profile for the purposes
 * of injecting headers into the Axios Request Config.
 */
function getAuthProfile(): AuthProfile {
  try {
    const activeProfile = localStorage.getItem("active-profile");
    if (!activeProfile) {
      return null;
    }
    return JSON.parse(activeProfile);
  } catch (err) {
    console.error(err);
    return null;
  }
}

/**
 * Bearer Strategy for Mgmt API, Resident API, and Fusion API
 */
function bearerAuthStrategy({ accessToken }: { accessToken: string }) {
  return {
    Authorization: `Bearer ${accessToken}`,
  };
}

/**
 * Auth Strategy for Internal API and Vendor API
 */
function hmacAuthStrategy({
  secret,
  key,
  queryString,
  path,
  method,
  body,
}: {
  secret: string;
  key: string;
  queryString?: string | undefined;
  path: string;
  method: string;
  body?: Record<string, any>;
}) {
  const timestamp = Math.round(new Date().getTime() / 1000);
  const bodyOrString = body ? JSON.stringify(body) : "";
  const message = `${timestamp}\n${method}\n${path}${
    queryString ? `?${queryString}` : ""
  }\n${bodyOrString}`;

  return {
    "AUTHORIZATION-X-SMARTRENT-TS": timestamp,
    "AUTHORIZATION-X-SMARTRENT-SIGNATURE": CryptoJS.enc.Hex.stringify(
      CryptoJS.HmacSHA512(message, secret)
    ),
    "AUTHORIZATION-X-SMARTRENT-KEY": key,
  };
}

/**
 * Attempts to apply various known CMW Auth strategies to the Request Headers based on what is contained in AuthProfile.
 *
 * Auth Strategies that we attempt to apply:
 *
 * - Bearer
 * - HmacChecksum
 */
function applyAuthHeaders({
  profile,
  path,
  queryString,
  method,
  body,
}: {
  profile: AuthProfile;
  body: Record<string, any> | undefined;
  path: string;
  queryString: string;
  method: string;
}): Record<string, any> {
  if (profile?.accessToken) {
    return bearerAuthStrategy({ accessToken: profile.accessToken });
  } else if (profile?.key && profile?.secret) {
    return hmacAuthStrategy({
      secret: profile.secret,
      key: profile.key,
      queryString,
      path,
      method,
      body,
    });
  }

  return {};
}

export const applyAxiosInterceptors = ({
  axios,
  storeHistoricResponse,
}: {
  axios: Axios;
  storeHistoricResponse: (payload: any) => void;
}) => {
  axios.interceptors.request.use((config) => {
    const profile = getAuthProfile();
    const params = config.params || {};
    const queryString =
      config.paramsSerializer && params
        ? config.paramsSerializer?.serialize?.(params) || ""
        : "";
    const body: Record<string, any> = config.data;
    const method = config.method ? config.method.toUpperCase() : "";
    const path = config.url
      ? config.url.replace(config.baseURL ? config.baseURL : "", "")
      : "";

    return {
      ...config,
      headers: {
        ...config?.headers,
        ...applyAuthHeaders({ profile, body, path, queryString, method }),
      } as AxiosRequestHeaders,
    };
  });

  axios.interceptors.response.use(
    (response: AxiosResponse) => {
      storeHistoricResponse({
        metadata: { profile: getAuthProfile() },
        response,
        error: null,
      }); // Adds success response to BadMagic's `History` tab
      return response;
    },
    (error: AxiosError) => {
      const metadata = { profile: getAuthProfile() };
      storeHistoricResponse({
        metadata,
        response: null,
        error,
      }); // Adds error response to BadMagic's `History` tab
      return Promise.reject(error);
    }
  );

  return axios;
};
