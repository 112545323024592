import { Route } from "badmagic/dist/types";

const basePath = "/api/v1";

const routes: Route[] = [
  {
    name: "Accept Company Invitation",
    path: `${basePath}/company-invitations/:id/accept-invitation`,
    method: "patch",
  },
];

export default routes;
