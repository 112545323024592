import { Route } from "badmagic/dist/types";

const basePath = "/api/v1";

const workersRoutes: Route[] = [
  {
    name: "Check an Oban jobs status",
    path: `${basePath}/workers/:worker_id`,
    documentation: "`:worker_id` can be found from the database `oban_jobs.id`",
  },
];

export default workersRoutes;
