import type { Workspace } from "badmagic/dist/types";

import authentication from "./mobile-app/authentication";
import jobs from "./mobile-app/jobs";
import jobTemplates from "./mobile-app/job-templates";
import miscellaneous from "./mobile-app/miscellaneous";
import projects from "./mobile-app/projects";
import properties from "./mobile-app/properties";
import tasks from "./mobile-app/tasks";
import users from "./mobile-app/users";
import hub from "./mobile-app/hub";
import devices from "./mobile-app/devices";
import taskNameSuggestions from "./mobile-app/task-name-suggestions";
import units from "./mobile-app/units";
import workers from "./mobile-app/workers";
import userCompanies from "./mobile-app/user-company";
import companyInvitations from "./mobile-app/company-invitation";
import installDetailsRoutes from "./mobile-app/install-details";
import notificationRoutes from "./mobile-app/notifications";
import featureFlags from "./mobile-app/feature-flags";
import teams from "./mobile-app/teams";

const mobileWorkspace: Workspace = {
  id: "api",
  name: "Mobile App API",
  config: {
    baseUrl: window.location.origin,
    loginPath: "/api/v1/login",
    refreshPath: "/api/v1/refresh",
    pathToTokens: "data",
    authStrategy: "bearer",
  },
  routes: [
    ...authentication,
    ...users,
    ...projects,
    ...properties,
    ...jobTemplates,
    ...jobs,
    ...units,
    ...tasks,
    ...miscellaneous,
    ...hub,
    ...devices,
    ...taskNameSuggestions,
    ...workers,
    ...userCompanies,
    ...companyInvitations,
    ...installDetailsRoutes,
    ...notificationRoutes,
    ...featureFlags,
    ...teams,
  ],
};

export default mobileWorkspace;
