import type { Route } from "badmagic/dist/types";

const basePath = "/api/v1/notifications";

const notificationRoutes: Route[] = [
  {
    name: "Get Notifications",
    path: basePath,
  },
  {
    name: "Update a notification",
    path: `${basePath}/:notification_id`,
    method: "PATCH",
    body: [
      {
        name: "read_at",
        type: "datetime",
        placeholder: "2022-10-06T17:40:29Z",
      },
    ],
  },
];

export default notificationRoutes;
