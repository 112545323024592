import { Workspace } from "badmagic/dist/types";

import companyPropertySyncSettings from "./admin/company-property-sync-settings";
import companyUnitSyncSettings from "./admin/company-unit-sync-settings";

const basePath = `/super-admin/v1`;

const initialRoutes = [
  {
    name: "Create External Application",
    path: `${basePath}/external-application`,
    method: "POST",
    body: [
      { name: "name", required: true },
      { name: "key", required: true },
      { name: "secret", required: true },
      { name: "company_ids", type: "textarea", required: false, json: true },
    ],
  },
  {
    name: "Get Available Workers",
    path: `${basePath}/workers`,
  },
  {
    name: "Run Worker",
    path: `${basePath}/workers/:id`,
    method: "PUT",
    body: [
      {
        name: "units_inserted_after",
        placeholder: "2022-10-06T17:40:29Z",
        required: false,
      },
    ],
  },
  {
    name: "Update AI Mobile app version requirements",
    path: `${basePath}/app-versions`,
    method: "POST",
    body: [
      {
        name: "current_version",
      },
      {
        name: "minimum_required_version",
      },
    ],
  },
  {
    name: "Publish or update release notes",
    path: `${basePath}/release-notes`,
    method: "POST",
    body: [
      { name: "version" },
      { name: "release_date", type: "date" },
      {
        name: "features",
        array: true,
        properties: [{ name: "title" }, { name: "description" }],
      },
    ],
  },
  {
    name: "Update global authentication settings",
    path: `${basePath}/auth/config`,
    method: "PUT",
    body: [
      {
        name: "accept_tokens_issued_after",
        placeholder: "2022-10-06T17:40:29.541755Z",
      },
    ],
  },
  {
    name: "Send a push notification",
    path: `${basePath}/push-notifications`,
    method: "POST",
    body: [{ name: "user_id" }, { name: "title" }, { name: "body" }],
  },
  {
    name: "Rotate signing keys",
    path: `${basePath}/auth/keys/signing/rotate`,
    method: "POST",
  },
  {
    name: "List All Feature Flags",
    path: `${basePath}/feature-flags`,
  },
  {
    name: "Toggle a feature flag",
    path: `${basePath}/feature-flags/:id`,
    method: "PATCH",
    body: [
      {
        name: "actor",
        required: true,
        options: [{ value: "global" }, { value: "company" }],
      },
      {
        name: "actor_id",
      },
    ],
  },
];

const superAdminWorkspace: Workspace = {
  id: "superAdmin",
  name: "Super Admin API",
  config: {
    baseUrl: window.location.origin,
    authStrategy: "hmac",
  },
  routes: [
    ...initialRoutes,
    ...companyPropertySyncSettings,
    ...companyUnitSyncSettings,
  ],
};

export default superAdminWorkspace;
